import React from "react"
import { Link } from "gatsby"
import Layout from "components/Layout/Layout"
import SEO from "components/Layout/Seo"
import styled from "styled-components"
import { Container, Row, Col } from "reactstrap"

const Wrapper = styled(Row)`
  max-width: 1140px;
  padding: 80px 0;
  text-align: center;
`
const HeadingOne = styled.h1`
  ${props => props.theme.main.fonts.headingOne};
  font-size: 90px;
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
`
const ParagraphTwo = styled.p`
  ${props => props.theme.main.fonts.paragraphTwo};
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Page Not found" />
    <Container fluid>
      <Wrapper>
        <Col>
          <HeadingOne>404</HeadingOne>
          <HeadingTwo>PAGE NOT FOUND</HeadingTwo>
          <ParagraphTwo>
            This page doesn&#39;t exist...{" "}
            <Link className="nav-link" to="/">
              Return home
            </Link>
          </ParagraphTwo>
        </Col>
      </Wrapper>
    </Container>
  </Layout>
)

export default NotFoundPage
